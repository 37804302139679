<template>
  <div>
    <b-card-actions
      ref="pendingCreatorApllicationsCard"
      title="Pending Creator Apllications"
      action-refresh
      @refresh="refreshStop('pendingCreatorApllicationsCard')"
    >
      <b-table
        responsive
        :items="items"
        :fields="fields"
        class="mb-0"
      >
        <!-- Column: Active -->
        <template #cell(active)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="data.item.active"
              name="check-button"
              switch
              inline
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button
            variant="primary"
            class="btn-icon ml-2"
            :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
          >
            <feather-icon icon="UserIcon" />
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon ml-2"
            :to="{ name: 'apps-user-application', params: { id: data.item.user_id } }"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </b-card-actions>
    <b-modal
      id="modal-center"
      centered
      title="Vertically Centered"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        Croissant jelly-o halvah chocolate sesame snaps.
        Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love.
        Gummies macaroon donut caramels biscuit topping danish.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import api from '@mlfcore/api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTable, BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import tempData from '@mlfcore/api/tempdata'

export default {
  components: {
    BCardActions,
    BTable,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      card: 'pendingCreatorApllicationsCard',
      fields: [
        { key: 'row_id', label: 'ID' },
        { key: 'username', label: 'Username' },
        { key: 'created_at', label: 'Created At' },
        { key: 'action', label: 'Action' },
      ],
      items: tempData,
    }
  },
  mounted() {
    this.$refs[this.card].showLoading = true
    this.initalize()
  },
  methods: {
    initalize() {
      this.getData()
    },
    getData() {
      api.getCreator('applications/pending', {
        count: 10,
        page: 1,
      }).then(response => {
        /* eslint-disable no-console */
        console.log(response)
        this.items = response.data
      }).catch(error => {
        /* eslint-disable no-console */
        console.log(error)
      }).then(() => {
        this.$refs[this.card].showLoading = false
      })
    },
    // stop refreshing card in 3 sec
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
}
</script>
